/* Full Calendar */

.fc {
    button {
        @extend .btn;
        @extend .btn-sm;
        height: auto;
        background-image: none;
        text-shadow: none;
        font-weight: 400;
        background-color: $white;
        border-color: $border-color;
        text-transform: capitalize;
        color: $fullcalendar-color;
        .fc-icon-chevron-right,
        .fc-icon-chevron-left {
            &::before {
                color: $fullcalendar-color;
                font: normal normal normal 20px/1 "Material Design Icons";
            }
        }
        .fc-icon-chevron-right {
            &::before {
                content: "\F142";
            }
        }
        .fc-icon-chevron-left {
            &::before {
                content: "\F141";
            }
        }
    }
    .fc-button-group {
        @extend .btn-group;
        .fc-button {
            background-color: $white;
            margin: 0;
            @media (max-width: 1024px) {
                padding: 0.5rem;
            }
            &.fc-state-active {
                color: theme-color(danger);
            }
        }
        .fc-button-primary {
            &:not(:disabled) {
                &.fc-button-active {
                    @extend .btn-primary;
                }
            }
            &:hover {
                border-color: $border-color;
            }
        }
    }
    .fc-header-toolbar {
        margin-top: 2rem;
        .fc-left {
            .fc-today-button {
                @extend .btn-primary;
            }
        }
        .fc-center {
            h2 {
                font-size: 1rem;
            }
        }
        @media (max-width: 767px) {
            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                margin-bottom: 10px;
                &:after {
                    content: "";
                    clear: both;
                    display: block;
                }
            }
            .fc-left {
                .fc-today-button {
                    float: right;
                }
            }
            .fc-right {
                .fc-button-group {
                    float: none;
                }
            }
        }
    }
    @media (max-width: 600px) {
        .fc-header-toolbar {
            display: block;
            .fc-left {
                .fc-button-group {
                    display: block;
                    margin-bottom: 0.75rem;
                }
                .fc-today-button {
                    float: none;
                    margin: 0;
                }
            }
        }
    }
    .fc-widget-header {
        border: 0;
        table {
            tr {
                th {
                    border-width: 0 0 1px 0;
                    text-align: right;
                    padding: 1rem;
                    @media (max-width: 767px) {
                        padding: 0.125rem;
                        font-size: 12px;
                    }
                    span {
                        font-size: 10px;
                        text-transform: uppercase;
                        color: $fullcalendar-color;
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }
    .fc-content-skeleton {
        table {
            tr {
                td {
                    padding: 0.5rem;
                    font-size: 0.875rem;
                    @media (max-width: 767px) {
                        padding: 0;
                        font-size: 12px;
                    }
                }
            }
        }
    }
    .fc-event {
        border: 0;
        color: $white;
        padding: 0.5rem;
        &.fc-start {
            background: theme-color(primary);
            border-radius: 4px;
            border-left: 4px solid theme-color(primary);
        }
        &.fc-not-start,
        &.fc-not-end {
            background: theme-color(danger);
            border-left: 4px solid theme-color(danger);
            padding-left: 0.5rem;
        }
        &.fc-not-end {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .fc-title,
        .fc-time {
            color: $white;
        }
    }
}

.fc-external-events {
    .fc-event {
        background: $white;
        color: $black;
        border: 0px solid;
        border-left-width: 4px;
        padding: 16px;
        border-radius: 0;
        margin-bottom: 10px;
        transition: color 0.3s ease-in-out;
        &:nth-child(1) {
            border-color: theme-color(primary);
        }
        &:nth-child(2) {
            border-color: theme-color(danger);
        }
        &:nth-child(3) {
            border-color: theme-color(success);
        }
        &:nth-child(4) {
            border-color: theme-color(info);
        }
        &:nth-child(4) {
            border-color: theme-color(warning);
        }
        &:hover {
            color: theme-color(primary);
        }
    }
}
