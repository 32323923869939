/* Modals */

.modal {
    .modal-dialog {
        margin-top: 100px;
        .modal-content {
            .modal-header {
                padding: $modal-header-padding-y $modal-header-padding-x;
                .close {
                    span {
                        font-size: 20px;
                        font-weight: 400;
                        color: #6a6a6a;
                    }
                }
            }
            .modal-body {
                padding: $modal-body-padding-y $modal-body-padding-x;
            }
            .modal-footer {
                padding: $modal-footer-padding-y $modal-footer-padding-x;
                @media (max-width: 400px) {
                    padding-left: 0.25rem;
                    padding-right: 0.25rem;
                    flex-direction: column;
                    > :not(:last-child) {
                        margin-right: 0;
                        margin-bottom: 0.7rem;
                    }
                    > :not(:first-child) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
