/* Accordions */

.accordion {
    .card {
        margin-bottom: 0.75rem;
        box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
        border-radius: 0.25rem;
        .card-header {
            background-color: transparent;
            border: none;
            padding: 2rem;
            * {
                font-weight: 400;
                font-size: 1rem;
            }
            a {
                display: block;
                color: inherit;
                text-decoration: none;
                font-size: inherit;
                position: relative;
                @include transition(color 0.5s ease);
                padding-right: 1.5rem;
                &:before {
                    font-family: "themify";
                    position: absolute;
                    right: 7px;
                    top: 0;
                    font-size: 14px;
                    display: block;
                }
                &[aria-expanded="true"] {
                    color: theme-color(primary);
                    &:before {
                        content: "\e622";
                    }
                }
                &[aria-expanded="false"] {
                    &:before {
                        content: "\e61a";
                    }
                }
            }
        }
        .card-body {
            font-size: 14px;
            padding: 0 2rem 2rem 2rem;
            font-weight: 400;
            line-height: 1.5;
            i {
                font-size: 1.25rem;
            }
        }
    }
    &.accordion-bordered {
        background: $white;
        box-shadow: 0px 1px 15px 1px rgba(230, 234, 236, 0.35);
        .card {
            margin: 0 2rem;
            border-top: 1px solid $border-color;
            box-shadow: none;
            border-radius: 0;
            border-left: 0;
            border-right: 0;
            .card-header,
            .card-body {
                padding-left: 0;
                padding-right: 0;
            }
            .card-header {
                a {
                    &:before {
                        color: theme-color(danger);
                    }
                    &[aria-expanded="true"] {
                        color: inherit;
                        &:before {
                            content: "\e65c";
                        }
                    }
                    &[aria-expanded="false"] {
                        &:before {
                            content: "\e65f";
                        }
                    }
                }
            }
            &:first-child {
                border-top: 0;
            }
            &:last-child {
                border-bottom: 0;
            }
        }
    }
    &.accordion-filled {
        .card {
            padding: 0;
            .card-header {
                padding: 0;
                a {
                    padding: 2rem 4.5rem 2rem 2rem;
                    @include transition(all 0.2s linear);
                    &:before {
                        top: 40%;
                        right: 40px;
                    }
                    &[aria-expanded="true"] {
                        background: theme-color(info);
                        color: $white;
                        &:before {
                            content: "\e648";
                            color: $white;
                        }
                    }
                    &[aria-expanded="false"] {
                        &:before {
                            content: "\e64b";
                        }
                    }
                }
            }
            .card-body {
                padding: 0 2rem 2rem 2rem;
                background: theme-color(info);
                color: $white;
            }
        }
    }
    &.accordion-solid-header {
        .card {
            padding: 0;
            .card-header {
                padding: 0;
                a {
                    padding: 2rem 4.5rem 2rem 2rem;
                    @include transition(all 0.2s linear);
                    &:before {
                        top: 40%;
                        right: 40px;
                    }
                    &[aria-expanded="true"] {
                        background: theme-color(primary);
                        color: $white;
                        &:before {
                            content: "\e648";
                            color: $white;
                        }
                    }
                    &[aria-expanded="false"] {
                        &:before {
                            content: "\e64b";
                        }
                    }
                }
            }
            .card-body {
                padding: 2rem;
            }
        }
    }
    &.accordion-solid-content {
        .card {
            padding: 0;
            .card-header {
                padding: 0;
                a {
                    padding: 2rem 4.5rem 2rem 2rem;
                    @include transition(all 0.2s linear);
                    &:before {
                        top: 40%;
                        right: 40px;
                    }
                    &[aria-expanded="true"] {
                        color: inherit;
                        &:before {
                            content: "\e648";
                        }
                    }
                    &[aria-expanded="false"] {
                        &:before {
                            content: "\e64b";
                        }
                    }
                }
            }
            .card-body {
                padding: 2rem;
                background: theme-color(success);
                color: $white;
            }
        }
    }
    &.accordion-multi-colored {
        .card {
            .card-header,
            .card-body {
                background: transparent;
                color: $white;
            }
            &:nth-child(1) {
                background: theme-color(primary);
            }
            &:nth-child(2) {
                background: theme-color(success);
            }
            &:nth-child(3) {
                background: theme-color(danger);
            }
            &:nth-child(4) {
                background: theme-color(warning);
            }
            &:nth-child(5) {
                background: theme-color(info);
            }
            .card-header {
                a {
                    &[aria-expanded="true"] {
                        color: inherit;
                        &:before {
                            content: "\e648";
                        }
                    }
                    &[aria-expanded="false"] {
                        &:before {
                            content: "\e64b";
                        }
                    }
                }
            }
        }
    }
}
