/* Bootstrap Alerts */

.alert {
    font-size: $default-font-size;
    i {
        font-size: 1.25rem;
        margin-right: 1.25rem;
        vertical-align: middle;
        line-height: 0.5;
    }
}

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        @include alert-variant(
            rgba(theme-color($color), 0.2),
            theme-color-level($color, 1),
            theme-color-level($color, 3)
        );
    }
}

@each $color, $value in $theme-colors {
    .alert-fill-#{$color} {
        @include alert-variant(
            theme-color($color),
            theme-color($color),
            color(white)
        );
    }
}
