/* Popovers */

.popover-static-demo {
    .popover {
        width: 185px;
        position: relative;
        display: block;
        float: left;
        margin: 1rem 0.5rem;
        z-index: 10;
        &.bs-popover-bottom-demo,
        &.bs-popover-top-demo {
            .arrow {
                left: 50%;
            }
        }
        &.bs-popover-left-demo,
        &.bs-popover-right-demo {
            .arrow {
                top: 50%;
            }
        }
    }
}

.popover {
    z-index: 1029;
    &.bs-popover-top-demo {
        .arrow {
            &:before {
                border-top-color: $popover-border-color;
            }
            &:after {
                border-top-color: $popover-arrow-color;
            }
        }
    }
    &.bs-popover-right-demo {
        .arrow {
            &:before {
                border-right-color: $popover-border-color;
            }
            &:after {
                border-right-color: $popover-arrow-color;
            }
        }
    }
    &.bs-popover-bottom-demo {
        .arrow {
            &:before {
                border-bottom-color: $popover-border-color;
            }
            &:after {
                border-bottom-color: $popover-arrow-color;
            }
        }
        .popover-header {
            &:before {
                border-bottom: 0;
            }
        }
    }
    &.bs-popover-left-demo {
        .arrow {
            &:before {
                border-left-color: $popover-border-color;
            }
            &:after {
                border-left-color: $popover-arrow-color;
            }
        }
    }
    .popover-header {
        font-size: 0.9375rem;
        border-bottom: 0;
        background: $white;
        color: inherit;
        border-bottom: 1px solid $popover-border-color;
    }
    .popover-body {
        color: $text-muted;
    }
    &.left {
        .arrow {
            &:before {
                border-left-color: $popover-border-color;
            }
            &:after {
                border-left-color: $popover-arrow-color;
            }
        }
    }
    &.right {
        .arrow {
            &:before {
                border-right-color: $popover-border-color;
            }
            &:after {
                border-right-color: $popover-arrow-color;
            }
        }
    }
    &.top {
        .arrow {
            &:before {
                border-top-color: $popover-border-color;
            }
            &:after {
                border-top-color: $popover-arrow-color;
            }
        }
    }
    &.bottom {
        .arrow {
            &:before {
                border-bottom-color: $popover-border-color;
            }
            &:after {
                border-bottom-color: $popover-arrow-color;
            }
        }
    }
}

@each $color, $value in $theme-colors {
    .popover-#{$color} {
        @include popover-variant($color);
    }
}
