/* Datepicker */
.datepicker.datepicker-dropdown,
.datepicker.datepicker-inline {
	padding: 0 25px;
	width: 30%;
	max-width: 500px;
	min-width: 250px;

	.datepicker-days {
		padding: 0.8rem 0.7rem;

		table.table-condensed {
			width: 100%;

			thead {
				tr {
					th {
						text-align: center;
						padding: 0.5rem 0;

						&.prev {
							color: color(gray);
							padding-bottom: 1rem;
							padding-top: 1rem;
							text-align: left;
							background: $card-bg;
						}

						&.datepicker-switch {
							color: color(gray);
							background: $card-bg;
							padding-bottom: 1rem;
							padding-top: 1rem;
							font-size: 1rem;
							font-weight: 600;
						}

						&.next {
							color: grey;
							padding-bottom: 1rem;
							padding-top: 1rem;
							text-align: right;
							background: $card-bg;
						}

						&.dow {
							font-family: $type-1;
							color: color(gray);
							font-size: 0.875rem;
							font-weight: initial;

							&:first-child {
								text-align: left;
							}

							&:last-child {
								text-align: right;
							}
						}
					}
				}
			}

			tbody {
				position: relative;
				top: 13px;

				td {
					text-align: center;

					&.day {
						font-size: 0.9375rem;
						padding: 0.5rem 0;
						color: $body-color;

						&:hover {
							background: lighten($card-bg, 4%);
						}

						&:first-child {
							text-align: left;
						}

						&:last-child {
							text-align: right;
						}

						&.active {
							color: #fff;
							background: transparent;
							position: relative;
							z-index: 1;

							&:before {
								content: "";
								width: 38px;
								height: 38px;
								background: theme-color(success);
								border-radius: 100%;
								display: block;
								margin: auto;
								vertical-align: middle;
								position: absolute;
								top: 1px;
								z-index: -1;
								left: 0;
								right: 0;
							}
						}

						&.today {
							color: #fff;
							background: transparent;
							position: relative;
							z-index: 1;

							&:before {
								content: "";
								width: 38px;
								height: 38px;
								background: theme-color(primary);
								border-radius: 100%;
								display: block;
								margin: auto;
								vertical-align: middle;
								position: absolute;
								top: 1px;
								z-index: -1;
								left: 0;
								right: 0;
							}
						}
					}

					&.old.day {
						color: darken(color(gray-lightest), 4.5%);
					}

					&:first-child {
						&.day {

							&.active,
							&.today {
								&:before {
									left: -42px;
								}
							}
						}
					}

					&:last-child {
						&.day {

							&.active,
							&.today {
								&:before {
									left: 30px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.datepicker.datepicker-inline {
	width: 100%;
	max-width: 100%;
	min-width: 250px;

	thead {
		tr {
			th {
				&.prev {
					color: $body-color;
					padding-bottom: 0.5rem;
					padding-top: 0.5rem;
				}

				&.datepicker-switch {
					color: theme-color(primary);
					padding-bottom: 0.5rem;
					padding-top: 0.5rem;
				}

				&.next {
					color: $body-color;
					padding-bottom: 0.5rem;
					padding-top: 0.5rem;
				}
			}
		}
	}
}

.datepicker {
	>div {
		display: initial;
		padding: 0.375rem 0.75rem;
		margin-bottom: 0;
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.5;
		color: $body-color;
		text-align: center;
		white-space: nowrap;
		border-radius: 2px;
	}

	&.input-group {
		border: 1px solid $border-color;
		padding: 0;

		.form-control {
			border: none;
		}
	}
}

.datepicker-custom {
	width: 100%;
	padding: 0;
	.datepicker {
		&.datepicker-inline {
			display: block;
			padding: 0;
			height: 100%;
			.datepicker-days {
				display: block;
				.table-condensed {
					tbody {
						tr {
							td {
								padding: 1rem;
								@media (max-width: 991px) {
									padding: 1rem .5rem;
								}
								text-align: center;
								&.day {
									&.today {
										&:before {
											@extend .bg-success;
											top: 13px;
										}
									}
									&.active {
										&:before {
											@extend .bg-warning;
											top: 13px;
										}
									}
									&:first-child {
										padding-left: 2.5rem;
										@media (max-width: 991px) {
											padding-left: .5rem;
										}
										&.today,
										&.active {
											&:before {
												left: 1.5rem;
												@media (max-width: 991px) {
													left: .25rem;
												}
											}
										}
									}
									&:last-child {
										padding-right: 2.5rem;
										@media (max-width: 991px) {
											padding-right: .5rem;
										}
										&.today,
										&.active {
											&:before {
												right: 1.5rem;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.datepicker-days,
			.datepicker-months,
			.datepicker-years,
			.datepicker-decades,
			.datepicker-centuries {
				height: 100%;
				padding: 0 0 1rem 0;				
				.table-condensed {
					height: 100%;
					thead {
						background: theme-color(success);
						tr {
							th {
								&.next,
								&.prev,
								&.datepicker-switch,
								&.dow {
									background: transparent;
									border-radius: 0;
									color: $white;
									padding: 2rem 1rem;
									@media (max-width: 991px) {
										padding: 2rem .5rem;
									}
									font-weight: bold;
									&:first-child {
										padding-left: 2.5rem;
										@media (max-width: 991px) {
											padding-left: .5rem;
										}
									}
									&:last-child {
										padding-right: 2.5rem;
										@media (max-width: 991px) {
											padding-right: .5rem;
										}
									}
								}
								&.datepicker-switch {
									font-size: 2rem;
									@media (max-width: 991px) {
										font-size: 1rem;
									}
								}
								&.next,
								&.prev {
									font-size: 1.5rem;
								}
							}
						}
					}
				}
			}
		}
	}
}