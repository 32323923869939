/* Badges */

.badge {
    border-radius: 0;
    font-size: 12px;
    line-height: 1;
    padding: 0.375rem 0.5625rem;
    font-weight: normal;
    &.badge-pill {
        border-radius: 10rem;
    }
}

/*Badge outlined variations*/
@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        @include badge-outline-variations($value);
    }
}
